<template>

  <div class="mapbox">
    <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 90%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />

      <l-marker :lat-lng="iconData.latlng">
        <l-icon
          :icon-anchor="iconData.staticAnchor"
          class-name="mapIconBox" >
          <!--div class="headline"> my txt </div-->
          <img src="../assets/icons/building1.png" height="35px">
        </l-icon>
        <l-popup>
          <div> Elogio del Horizonte </div>
        </l-popup>
      </l-marker>

      <l-marker v-for="(pdi, index) in points" :key="'pt'+index" :lat-lng="pdi.latLng">
        <l-popup>
          <div>
            {{pdi.nombre}}
            <p v-show="showParagraph"> 
              <strong>Visitas dia:</strong> {{pdi.vis24h}} <br>
              <strong>Visitas mes:</strong> {{pdi.vis1m}} <br>
              <strong>Visitas Año:</strong> {{pdi.vis1a}} <br>
            </p>
          </div>
        </l-popup>
      </l-marker>
      <l-circle v-for="pdi in points" :key="'circ'+pdi.id" 
        :visible="showVisitorLayer" 
        :lat-lng="pdi.circCenter"
        :radius="circle.radius"
        :color="pdi.circColor"
        :opacity="circle.opacity"
        :fill-opacity="circle.opacity"
        :fill-color="pdi.circColor"
      />
    </l-map>
    <div class="maplegend">
      <span>Ver/Ocultar </span>
      <DxButton
        text="Stats PDI"
        @click="showLongText" />
        <DxButton
        text="Tráfico visitantes"
        @click="showVisitCircles" />
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import { latLng, Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LCircle, LIcon } from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

let maxVisit1mes = 5000;
const getMaxVis1m = (tablepdi) => {
  let arrVis1m = tablepdi.map(x => x.vis1m);
  let maxVis1m = Math.max(... arrVis1m);
  maxVisit1mes = maxVis1m;
  return maxVis1m
}
let colorscale = [
  '#fee8c8', '#ffe0bd', '#ffd9b2', '#ffd1a8', '#fec99f', 
  '#fdc196', '#fdb98d', '#fbb285', '#faaa7d', '#f9a275', 
  '#f79a6e', '#f59266', '#f4895f', '#f28158', '#ef7952', 
  '#ed704b', '#eb6745', '#e85e3f', '#e65439', '#e34a33'
  ];
const getCircleColor = (min, max, val) => {
  let range= max-min
  let vRound=Math.floor((colorscale.length-1)*(val-min)/range)
  return colorscale[vRound]
}

export default {
  name: "MapGijon",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LCircle,
    LIcon,
    DxButton,
  },
  props: {
    tablepdi: Array
  },
  data() {
    return {
      zoom: 16,
      center: latLng(43.54734165259754, -5.6624299567411756), //parking cimavilla
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors & UpIntelligence',
      currentZoom: 16,
      currentCenter: latLng(43.54734165259754, -5.6624299567411756), //init parking cimavilla
      showParagraph: false,
      showVisitorLayer: true,
      mapOptions: { zoomSnap: 0.5 },
      showMap: true,
      getMaxVisit1mes: getMaxVis1m(this.tablepdi),
      points: this.tablepdi.map( x => {
        return { 
          id: x.id,
          nombre:x.nombre, 
          latLng: latLng(x.latitud, x.longitud),
          vis24h: x.vis24h,
          vis1m: x.vis1m,
          vis1a: x.vis1a,
          circColor: getCircleColor(0, maxVisit1mes, x.vis1m),
          circCenter: [x.latitud, x.longitud]
        }
      }),
      circle: { // default and common values for circles
        radius: 40,
        opacity: 0.5
      },
      iconData: {
        staticAnchor: [16, 37],
        latlng: [43.54887824657436, -5.663029101608896], //elogio
      }
    }
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    showVisitCircles() {
      this.showVisitorLayer = !this.showVisitorLayer;
    },
  }
};
</script>
<style >
.mapbox {
  height: 500px; 
  width: 100%;
}
.maplegend {
  height: 10%;
  overflow: auto;
}
.mapIconBox {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border: 3px solid rgb(132, 152, 218);
  border-radius: 0 17px 17px 17px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
</style>