export const regiones = [{
  region: 'Madrid',
  val: 1411
}, {
  region: 'Sevilla',
  val: 131
}, {
  region: 'Galicia',
  val: 3900
}, {
  region: 'Pais Vasco',
  val: 198
}, {
  region: 'Europa',
  val: 2689
}, {
  region: 'Americas',
  val: 78
}];

export const idiomas = [{
  idiomacount: 'Español',
  val: 27836
}, {
  idiomacount: 'Ingles',
  val: 990
}, {
  idiomacount: 'Frances',
  val: 79
}];

export const visitasGlobales = [{
  pdi:'Casa del chino',
  visitantes:28172
},
{
  pdi:'Capilla de los remedios',
  visitantes:27267
},
{
  pdi:'Plaza del llavaderu',
  visitantes:14311
},{

  pdi:'plaza del Marqués',
  visitantes:9197

}];

export const PDIvisitas = [{

  hora: 12,
  casa_chino:1200,
  capilla_remedios:8594,
  plaza_llavaderu:1002,
  plaza_marques:82
  
}, {
  hora: 9,
  casa_chino:9612,
  capilla_remedios:5500,
  plaza_llavaderu:3547,
  plaza_marques:500
  
},
{
  hora: 20,
  casa_chino:2114,
  capilla_remedios:6621,
  plaza_llavaderu:8802,
  plaza_marques:7520
},
{
  hora: 13,
  casa_chino:1100,
  capilla_remedios:50,
  plaza_llavaderu:900,
  plaza_marques:1000
},
{
  hora: 10,
  casa_chino:60,
  capilla_remedios:6502,
  plaza_llavaderu:60,
  plaza_marques:95
}];

export const sist_operativos= [{
  so: 'Android 5.0-',
  val: 81
},{
  so: 'Android 6.0+',
  val: 890
},{
  so: 'iOS 9+',
  val: 1230
}]

export const employee = {
  'ID': 1,
  'FirstName': 'John',
  'LastName': 'Heart',
  'Position': 'CEO',
  'BirthDate': '1964/03/16',
  'HireDate': '1995/01/15',
  'Notes': 'John has been in the Audio/Video industry since 1990. He has led DevAV as its CEO since 2003.\r\nWhen not working hard as the CEO, John loves to golf and bowl. He once bowled a perfect game of 300.',
  'Address': '351 S Hill St.',
  'City': 'Los Angeles',
  'State': 'CA',
  'ZipCode': '90013',
  'Home': '555-684-1334',
  'Mobile': '555-684-1335',
  'Email': 'jheart@dx-email.com',
  'Skype': 'jheart_DX_skype',
};

export const positions = [
  'HR Manager',
  'IT Manager',
  'Controller',
  'CEO',
  'Sales Manager',
  'Support Manager',
  'Shipping Manager'
];

export const clasificacion = [{
  clas: 3,
  pdi:'capilla de los remedios'

},{
  pdi:'Plaza del Marqués',
  clas: 1
}, {
  pdi:'casa del chino',
  clas: 5
}, {
  pdi:'Plaza del Llavaderu',
  clas: 2
}];

export const PDIfilter = 
[
  {
    "id": 0,
    "nombre": "Plaza del Marqués",
    "latitud": "43.5451",
    "longitud": "-5.66363",
    "vis24h": 3,
    "vis3d": 12,
    "vis1w": 24,
    "vis1m": 96,
    "vis3m": 540,
    "vis1a": 1080
  },
  {
    "id": 1,
    "nombre": "Capilla de los Remedios",
    "latitud": "43.5459",
    "longitud": "-5.66288",
    "vis24h": 4,
    "vis3d": 8,
    "vis1w": 36,
    "vis1m": 144,
    "vis3m": 672,
    "vis1a": 1344
  },
  {
    "id": 2,
    "nombre": "Casa Natal de Jovellanos",
    "latitud": "43.5459",
    "longitud": "-5.66258",
    "vis24h": 12,
    "vis3d": 36,
    "vis1w": 144,
    "vis1m": 576,
    "vis3m": 1812,
    "vis1a": 3624
  },
  {
    "id": 3,
    "nombre": "Plaza del Llavaderu",
    "latitud": "43.5465",
    "longitud": "-5.66291",
    "vis24h": 3,
    "vis3d": 12,
    "vis1w": 45,
    "vis1m": 180,
    "vis3m": 447,
    "vis1a": 894
  },
  {
    "id": 4,
    "nombre": "Casa del Chino",
    "latitud": "43.5472",
    "longitud": "-5.66474",
    "vis24h": 6,
    "vis3d": 12,
    "vis1w": 60,
    "vis1m": 240,
    "vis3m": 1080,
    "vis1a": 2160
  },
  {
    "id": 5,
    "nombre": "Plaza de la Corrada",
    "latitud": "43.5464",
    "longitud": "-5.66456",
    "vis24h": 18,
    "vis3d": 54,
    "vis1w": 180,
    "vis1m": 720,
    "vis3m": 3006,
    "vis1a": 6012
  },
  {
    "id": 6,
    "nombre": "Capilla de la Soledad",
    "latitud": "43.5468",
    "longitud": "-5.66513",
    "vis24h": 21,
    "vis3d": 84,
    "vis1w": 147,
    "vis1m": 588,
    "vis3m": 3150,
    "vis1a": 6300
  },
  {
    "id": 7,
    "nombre": "Casa Paquet",
    "latitud": "43.5457",
    "longitud": "-5.66445",
    "vis24h": 11,
    "vis3d": 33,
    "vis1w": 77,
    "vis1m": 308,
    "vis3m": 1980,
    "vis1a": 3960
  }
];

export const PDIfilterCal = 
[
  {
    "id": 0,
    "nombre": "Plaza del Marqués",
    "latitud": "43.5451",
    "longitud": "-5.66363",
    "Cal24h": 3,
    "Cal3d": 12,
    "Cal1w": 24,
    "Cal1m": 96,
    "Cal3m": 540,
    "Cal1a": 1080
  },
  {
    "id": 1,
    "nombre": "Capilla de los Remedios",
    "latitud": "43.5459",
    "longitud": "-5.66288",
    "Cal24h": 4,
    "Cal3d": 8,
    "Cal1w": 36,
    "Cal1m": 144,
    "Cal3m": 672,
    "Cal1a": 1344
  },
  {
    "id": 2,
    "nombre": "Casa Natal de Jovellanos",
    "latitud": "43.5459",
    "longitud": "-5.66258",
    "Cal24h": 12,
    "Cal3d": 36,
    "Cal1w": 144,
    "Cal1m": 576,
    "Cal3m": 1812,
    "Cal1a": 3624
  },
  {
    "id": 3,
    "nombre": "Plaza del Llavaderu",
    "latitud": "43.5465",
    "longitud": "-5.66291",
    "Cal24h": 3,
    "Cal3d": 12,
    "Cal1w": 45,
    "Cal1m": 180,
    "Cal3m": 447,
    "Cal1a": 894
  },
  {
    "id": 4,
    "nombre": "Casa del Chino",
    "latitud": "43.5472",
    "longitud": "-5.66474",
    "Cal24h": 6,
    "Cal3d": 12,
    "Cal1w": 60,
    "Cal1m": 240,
    "Cal3m": 1080,
    "Cal1a": 2160
  },
  {
    "id": 5,
    "nombre": "Plaza de la Corrada",
    "latitud": "43.5464",
    "longitud": "-5.66456",
    "Cal24h": 18,
    "Cal3d": 54,
    "Cal1w": 180,
    "Cal1m": 720,
    "Cal3m": 3006,
    "Cal1a": 6012
  },
  {
    "id": 6,
    "nombre": "Capilla de la Soledad",
    "latitud": "43.5468",
    "longitud": "-5.66513",
    "Cal24h": 21,
    "Cal3d": 84,
    "Cal1w": 147,
    "Cal1m": 588,
    "Cal3m": 3150,
    "Cal1a": 6300
  },
  {
    "id": 7,
    "nombre": "Casa Paquet",
    "latitud": "43.5457",
    "longitud": "-5.66445",
    "Cal24h": 11,
    "Cal3d": 33,
    "Cal1w": 77,
    "Cal1m": 308,
    "Cal3m": 1980,
    "Cal1a": 3960
  }
];

export const states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

