<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-lg-5">
        <MapGijon :tablepdi="PDIfilterData"/>
      </div>
      <div class="col-sm-12 col-lg-7">

        <DxChart
          id="chart"
          :data-source="PDIvisitData"
          title="Visitas/PDI"
        >

           <DxSize
            :height="475"
            />

           <DxSeries
            value-field="casa_chino"
            name="Casa chino"
            />
            <DxSeries
            value-field="capilla_remedios"
            name="Capilla de los remedios"
            />
            <DxSeries
            value-field="plaza_llavaderu"
            name="Plaza del llavaderu"
            />
            <DxSeries
            value-field="plaza_marques"
            name="Plaza del Marques"
            />
          <DxCommonSeriesSettings
            type="line"
            argument-field="hora"
            name="v/pdi"
          />
        </DxChart>
        
      </div>
    </div>
    <div class="row">

      <div class="col-sm-12 col-lg-6">
        <DxChart
          id="chart"
          :data-source="globalVisitData"
          title="visitas globales/PDI"
        >

          <DxSeries
            argument-field="pdi"
            value-field="visitantes"
            name="v/pdi"
            type="bar"
            color="#bf271b" 
          />
        </DxChart>

      </div>

      <div class="col-sm-12 col-lg-6">
        <DxChart
          id="chart"
          :data-source="clasifData"
          title="Calificaciones PDI"      
        >

        <DxSeries
          argument-field="pdi"
          value-field="clas"
          type="bar"
          color="#555654"
          name="c/pdi"
        />
        <DxExport
          :enabled="true"
        />
        </DxChart>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-4">
        <div class="row">
          <div class="col-12">
            <DxPieChart
              id="pie"
              :data-source="soData"
              type="doughnut"
              title="Sistemas Operativos"
              palette="Soft Pastel"
            >
              <DxSeries argument-field="so">
                <DxLabel
                  :visible="true"
                >
                <DxConnector :visible="true"/>
                </DxLabel>
              </DxSeries>
              <DxExport :enabled="true"/>
              <DxLegend
                :margin="0"
                horizontal-alignment="right"
                vertical-alignment="top"
              />
            </DxPieChart>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <DxPieChart
              id="pie"
              :data-source="idiomasData"
              type="doughnut"
              title="Idiomas"
              palette="Soft Pastel"
            >
              <DxSeries argument-field="idiomacount">
                <DxLabel
                  :visible="true"
                >
                <DxConnector :visible="true"/>
                </DxLabel>
              </DxSeries>
              <DxExport :enabled="true"/>
              <DxLegend
                :margin="0"
                horizontal-alignment="right"
                vertical-alignment="top"
                />
            </DxPieChart>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8">

       
        <h2 class="my-4">Tabla de datos de visitantes</h2>

        <DxDataGrid
          :data-source="PDIfilterData"
          :columns="columns"
          :show-borders="true"
          :columnsResizingMode="true"
        />
        <h2 class="my-4">Tabla de datos de calificaciones</h2>
        <DxDataGrid
          :data-source="PDIfilterDataCal"
          :columns="columnsCal"
          :show-borders="true"
          :columnsResizingMode="true"
        />

      </div>
    </div>

    <p>{{ msg }}</p>
    <p> Este Dashboard fue realizado por UpIntelligence Para el Proyecto --------- (R) 2021 </p>    
    
  </div>
</template>

<script>
import DxPieChart, {
  DxConnector,
  DxLegend,
  DxExport
} from 'devextreme-vue/pie-chart';
import { DxChart, DxSeries, DxLabel,DxCommonSeriesSettings, DxSize  } from 'devextreme-vue/chart';
//import { DxForm, DxSimpleItem } from 'devextreme-vue/form';
import { employee } from '../data.js';
import { sist_operativos,
         PDIvisitas,
         visitasGlobales,
         idiomas,
         clasificacion,
         PDIfilter,
         PDIfilterCal
} from '../data';

import DxDataGrid from 'devextreme-vue/data-grid';

import MapGijon from './MapGijon' 

export default {
  name: 'HelloWorld',
  components: {
    //DxForm,
    //DxSimpleItem,
    DxLegend,
    DxPieChart,
    DxSeries,
    DxLabel,
    DxConnector,
    DxExport,
    DxChart,
    DxCommonSeriesSettings,
    DxDataGrid,
    MapGijon,
    DxSize

  },
  props: {
    msg: String
  },
  data: function(){
    return{
     mydata: 1,
     formData: employee,
     soData: sist_operativos,
     globalVisitData: visitasGlobales,
     PDIvisitData:PDIvisitas,
     idiomasData: idiomas,
     clasifData:clasificacion,
     PDIfilterData: PDIfilter,
     columns:['nombre','vis24h','vis3d','vis1w','vis1m','vis3m','vis1a'],
     PDIfilterDataCal: PDIfilterCal,
     columnsCal:['nombre','Cal24h','Cal3d','Cal1w','Cal1m','Cal3m','Cal1a']
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
